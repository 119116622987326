<div class="apply-modal">
  <header class="apply-modal-header" [title]="job.title" theme="neutral">
    <utils>
      <util-action icon="times" (click)="close()"></util-action>
    </utils>
    <div class="job-details" *ngIf="job.publishedCategory && showCategory">
      {{ job.publishedCategory?.name }}
    </div>
    <div class="job-details">
      {{ job.address.city
      }}<span *ngIf="job.address?.city && job.address?.state">,&nbsp;</span
      >{{ job.address.state }}
      <span
        class="separator"
        *ngIf="(job.address?.city || job.address?.state) && job.employmentType"
        >|</span
      >
      <span class="type">{{ job.employmentType }}</span>
    </div>
  </header>
  <section *ngIf="!loading" [ngClass]="{ ios: isIos }">
    <novo-form [form]="form" layout="vertical">
      <div *ngFor="let control of formControls" class="novo-form-row">
        <novo-control [form]="form" [control]="control"></novo-control>
      </div>
      <div class="eeoc-form" *ngIf="eeocControls.length">
        <novo-fieldset-header
          title="{{ 'EEOC.EEOC_FORM_DESCRIPTION' | translate }}"
        ></novo-fieldset-header>
        <div *ngFor="let control of eeocControls" class="novo-form-row">
          <novo-control [form]="form" [control]="control"></novo-control>
        </div>
      </div>
      <div *ngIf="consentCheckbox" class="sms-terms">
        <h3>SMS Terms & Conditions</h3>
        <p>
          By subscribing, you sent consent to receive SMS or MMS messages from
          LaborWorx. To opt out of our text messaging program, send the word
          STOP to 470-432-6892 at any time. View our
          <a href="/terms">Terms of Service</a> and
          <a href="/privacy-policy">Privacy Policy</a>. Msg & Data rates may
          apply. Message frequency varies.
        </p>
        <div class="consent-form novo-form-row">
          <novo-control [form]="form" [control]="consentControl"></novo-control>
          <label class="consent-checkbox-label"
            >Yes, I'd like to recieve updates via text</label
          >
        </div>
      </div>
    </novo-form>
    <span *ngIf="hasError" class="error">{{
      "ERROR_WHILE_APPLYING" | translate
    }}</span>
  </section>
  <footer>
    <button
      theme="standard"
      (click)="close()"
      data-automation-id="create-modal-cancel"
    >
      {{ "CANCEL" | translate }}
    </button>
    <button
      theme="primary"
      icon="check"
      [disabled]="!form.valid"
      (click)="save()"
      data-automation-id="apply-modal-save"
      [loading]="applying"
    >
      {{ "APPLY" | translate }}
    </button>
  </footer>
</div>
