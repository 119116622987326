<span
  class="chip"
  *ngIf="job.address.city || job.address.state"
  data-automation-id="chip-address"
>
  <i class="bhi-location"></i>
  {{ job.address.city
  }}<span *ngIf="job.address?.city && job.address?.state">,&nbsp;</span
  >{{ job.address.state }}
</span>
<ng-container
  *ngFor="let chip of jobInfoChips"
  [ngSwitch]="chip.type || 'string'"
>
  <span
    class="chip"
    *ngIf="job[chip] || job[chip?.field]"
    [attr.data-automation-id]="'chip-' + chip?.field || chip"
  >
    <ng-container *ngSwitchCase="'string'">
      {{ job[chip?.field] || job[chip] }}
    </ng-container>
    <ng-container *ngSwitchCase="'mediumDate'">
      {{ job[chip?.field] | date : "mediumDate" }}
    </ng-container>
    <ng-container *ngSwitchCase="'dateTime'">
      {{ job[chip?.field] | date : "medium" }}
    </ng-container>
    <ng-container *ngSwitchCase="'USD'">
      {{ job[chip?.field] | currency : "USD" }}
    </ng-container>
    <ng-container *ngSwitchCase="'GBP'">
      {{ job[chip?.field] | currency : "GBP" }}
    </ng-container>
    <ng-container *ngSwitchCase="'EUR'">
      {{ job[chip?.field] | currency : "EUR" }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ job[chip?.field] || job[chip] }}
    </ng-container>
  </span>
</ng-container>
